/*! http://responsiveslides.com v1.32 by @viljamis */

.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  }

.rslides li {
  position: absolute;
  display: none;
  width: 100%;
  left: 0;
  top: 0;
  }

.rslides li:first-child {
  position: relative;
  display: block;
  float: left;
  }

.rslides img {
  display: block;
  height: auto;
  float: left;
  width: 100%;
  border: 0;
  }


  .callbacks_container {
  margin-bottom: 50px;
  position: relative;
  /*float: left;*/
  width: 100%;
  }

.callbacks {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  }

.callbacks li {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  }

.callbacks img {
  display: block;
  position: relative;
  z-index: 1;
  height: auto;
  width: 100%;
  border: 0;
  }

.callbacks .caption {
  display: block;
  position: absolute;
  z-index: 2;
  font-size: 20px;
  text-shadow: none;
  color: #fff;
  background: #000;
  background: rgba(0,0,0, .8);
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 20px;
  margin: 0;
  max-width: none;
  }

.callbacks_nav {
  position: absolute;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  top: 52%;
  left: 0;
  opacity: 0.7;
  z-index: 3;
  text-indent: -9999px;
  overflow: hidden;
  text-decoration: none;
  height: 61px;
  width: 38px;
  background: transparent url("../img/themes.gif") no-repeat left top;
  margin-top: -45px;
  }

.callbacks_nav:active {
  opacity: 1.0;
  }

.callbacks_nav.next {
  left: auto;
  background-position: right top;
  right: 0;
  }